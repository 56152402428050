@import "components/variables";
@import "components/hero";

.recommended__container {
  position: relative;
}

.recommended__slide {
  height: initial;
}

.recommended__left {
  position: relative;
  padding-top:100%;
  height:0;
  margin-bottom:35px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
    z-index: 1;
  }

  .product__label {
    left: initial;
    right: 15px;
  }
}

.recommended__right {
  padding-bottom: 50px;
  position: relative;

  .product__price {
    margin-bottom: 20px;
  }

  .btn {
    position: absolute;
    left: 00px;
    bottom: 0;
  }
  .product__discount{
    top:-80px;
  }
}
.recommended__title {
  padding-right: 110px;
}

.recommended__txt {
  color: $txt-dark;
  margin-bottom: 20px;
}

@media(min-width: $grid-breakpoints-md){
  .recommended__slide {
    display: flex;
  }
  .recommended__right {
    max-width: 65%;
    flex: 0 0 65%;
    padding-left: 60px;
    .product__discount{
      top:10px;
    }
    .btn {
      left: 60px;
    }

  }

  .recommended__left {
    max-width: 35%;
    flex: 0 0 35%;
    padding-top:35%;
    margin-bottom:0;
  }
}

@media(min-width: $grid-breakpoints-xl){

  .recommended__right {
    max-width: 60%;
    flex: 0 0 60%;
    .product__price {
      margin-bottom: 40px;
    }
  }

  .recommended__left {
    max-width: 40%;
    flex: 0 0 40%;
    padding-top:40%;
  }
  .recommended__txt {
    margin-bottom: 40px;
  }
}
@media(min-width: $grid-breakpoints-xxl){
  .recommended__right {
    padding-left: 120px;
    .btn {
      left: 120px;
    }
  }
}





.sa__container {
  position: relative;
  color: #fff;
  font-size: 20px;
line-height: 2;

  h2 {
    margin-bottom: 20px;
  }

  strong {
    font-size: 22px;
  }

  ul {
    list-style: initial;
    padding-left: 20px;
  }

  @media(max-width: $grid-breakpoints-xxl) {
   strong {
      font-size: 20px;
    }
    span {
      font-size: 18px;
    }
  }
  @media(max-width: $grid-breakpoints-xl) {
    line-height: 1.8;
  }

  @media(max-width: $grid-breakpoints-sm) {
    strong {
      font-size: 18px;
    }
    span{
      font-size: 16px;
    }
  }

}

.sa__img {
  position: absolute;
  left: 53%;
  bottom: 0;
  height: initial;
  width:47%;
  z-index:1;
}
.sa__img2 {
  position: absolute;
  left: 60%;
  top: 0;
  height: auto;
}

.sa__left{
  max-width: 60%;
  position: relative;
  z-index:2;
}

@media(max-width: $grid-breakpoints-lg){
  .sa__left {
    max-width: 100%;
  }
  .sa__img{
   left:50%;
    transform: translateX(-50%);
    right:50%;
    width:430px;
  }
  .sa__container{
    padding-bottom:300px;
  }
}

@media(max-width: $grid-breakpoints-sm){
   .sa__img{
    width:100%;
  }
  .sa__container{
    padding-bottom:70%;
  }
}





.teacher__container {
  position: relative;
}

.teacher__figure {
  padding-top: 77%;
  width: 100%;
  height: 0;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 15px;
  }
}

.teacher__txt {
  width: calc(100% - 20px);
  margin-left:10px;
  transform:translateY(-50%);
  padding: 20px 15px;
  border-radius:15px;
  background:#6BE6E1;
  font-size:14px;
  color:$txt;
  strong{
    font-size:18px;
    display:block;
    font-weight:400;
  }
}


.teacher__item:nth-child(2n){
  .teacher__txt{
    background-color: #C59EDD;
  }
}
.teacher__item:nth-child(3), .teacher__item:nth-child(7), .teacher__item:nth-child(11), .teacher__item:nth-child(15){
  .teacher__txt{
    background-color: #B4CEFA;
  }
}


.teacher__item:nth-child(4n){
  .teacher__txt{
    background-color: #F7ACA1;
  }
}

