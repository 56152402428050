.hero__outer {
  background: #EFF1F7;
  margin-top: -130px;
  overflow: hidden;

}

.hero {
  position: relative;
  z-index: 1;
  padding-top: 150px;
  background-image: url("../img/drop-1.png");
  background-size: 100%;
  background-position: bottom -220px right -120px;
  background-repeat: no-repeat;
  padding-bottom: 70px;
}

.hero__btns {
  display: flex;
  align-items: center;
  gap: 10px 20px;
  flex-wrap: wrap;
}

.hero__img {
  width: 100%;
  margin-bottom: 40px;
}

.hero__imgMap {
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:100%;
}

.hero__left {
  position: relative;
  z-index: 1;
}

.hero__drop2 {
  position: absolute;
  z-index: -1;
  width: 100%;
  top: 60%;
  left: 60%;
  transform: translate(-50%, -50%);
}

.hero__right {
  position: relative;
  z-index: 1;
  padding-top: 110px;
  padding-bottom: 110px;

  h2 {
    font-size: 19px;
    font-weight: 400;
    margin-bottom: 15px;
  }

  p {
    font-size: 14px;
    margin-bottom: 20px;
  }

  label {
    color: $main;
    font-size: 16px;
    display: block;
    margin-bottom: 10px;
  }

  input {
    width: 376px;
    max-width:100%;
    border: 3px solid $main;
    padding-left: 40px;
    background-image: url("../img/ico-search.svg");
    background-position: left 15px center;
    background-repeat: no-repeat;
    background-size: 15px;
    border-radius: 25px;
  }

}


.hero__search {
  margin-bottom: 10px;
}

.heroMap__label {
  font-size: 14px;
  position: relative;
  z-index: 1;
  margin-left: auto;
  display: block;
  width: fit-content;
  padding: 35px 10px 20px 10px;
  text-align: center;

  &:before {
    content: url("../img/bg-sale.svg");
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  strong {
    font-size: 20px;
  }
}

.hero__rightWrapper {
  display: block;
  width: 375px;
  margin-left: auto;
  margin-right: auto;
  max-width:100%;

}


@media(min-width: $grid-breakpoints-lg) {

  .hero__wrapper {
    display: flex;
    align-items: center;
  }

  .hero__left {
    width: 100%;
  }

  .hero__right {
    flex: 0 0 450px;
    max-width: 450px;
  }
  .hero__img {
    max-width: 90%;
  }
}
@media(min-width: $grid-breakpoints-xl) {
  .hero{
    padding-top: 200px;
  }
  .hero__right {
    flex: 0 0 590px;
    max-width: 590px;
  }
}



@media(min-width: $grid-breakpoints-xl) {
  .hero__wrapper {
    padding-right: 100px;
  }
}

@media(max-width: $grid-breakpoints-lg){
  .hero__wrapper{
    max-width:640px;
    padding-bottom:20px;
  }


  .hero__left{
    .btn{
      padding: 15px 15px;
    }
    .btn--sale {
      padding-left: 45px;
    }
  }
  .hero__btns{
    gap:10px;
  }
}

@media(max-width: $grid-breakpoints-sm) {
  .hero__rightWrapper{
    padding-top:0%;
  }

  .hero__imgMap {
    top: 30px;
    transform: translateX(-50%);
  }

  .hero__right {
    padding-top: 25%;
    padding-bottom: 15%;
    h2, p, label {
      margin-left: auto;
      margin-right: auto;
      max-width: 80%;
    }
    h2{
      margin-bottom:5px;
    }

  }
}

@media(max-width: $grid-breakpoints-sm) {

}


